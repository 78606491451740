import _ from 'lodash';
import block from 'bem-cn';
import VisibilitySensor from 'react-visibility-sensor';
import { MenuCategoriesItems } from '../../../constants/menuCategoriesItems';

import { MenuContentItem } from './MenuContentItem';
import { MenuContentComponent } from './MenuContent';

import './content.scss';
import './tbq.scss';

const b = block('menu__content');
const bTBQ = block('menu__tbq');

export class TQBContentComponent extends MenuContentComponent {
  render() {
    const { lang, id } = this.props;
    const category = MenuCategoriesItems[id];
    return (
      <VisibilitySensor
          onChange={this.onVisibilityChange}
          containment={this.state.container}
          resizeCheck={true}
          partialVisibility={true}
      >
        <div id={id} className={bTBQ()}>
          <div className={bTBQ('description')}>
            <div className={bTBQ('description__element')}>
              <span className={bTBQ('description__title')}>TACOS</span>
              <div className={bTBQ('description__text')}>
                Trzy tortille kukurydziane z farszem do wyboru oraz cebulą,
                kolendrą, limonką i salsą.
              </div>
            </div>
            <div className={bTBQ('description__element')}>
              <span className={bTBQ('description__title')}>QUESADILLA</span>
              <div className={bTBQ('description__text')}>
                Grillowana tortilla pszenna z serem, farsz do wyboru, kolendra i
                salsa.
              </div>
            </div>
            <div className={bTBQ('description__element')}>
              <span className={bTBQ('description__title')}>BURRITO</span>
              <div className={bTBQ('description__text')}>
                Tortilla pszenna ,fasola, ryż meksykański, ser, farsz do wyboru,
                salsa i frytki.
              </div>
            </div>
          </div>
          <div id={id} className={b('items', { type: 'tbq' })}>
            {_.map(category.content[0].items, (item, key) => (
              <MenuContentItem lang={lang} item={item} key={key} />
            ))}
          </div>
        </div>
      </VisibilitySensor>
    );
  }
}
