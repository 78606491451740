import { PureComponent } from 'react';
import {
  IMenuCategoryID,
  MenuCategoriesItems,
} from '../../../constants/menuCategoriesItems';
import { b } from './Categories';
import { ILanguage } from '../../../constants/languages';
import { visibleMenuElements } from '../../../redux/selector';

export interface ICategoryItemOwnProps {
  category: IMenuCategoryID;
  lang: ILanguage;
  visibleMenu: ReturnType<typeof visibleMenuElements>;
}

export class CategoryItem extends PureComponent<
  ICategoryItemOwnProps
> {
  render() {
    const { lang, category, visibleMenu } = this.props;
    const data = MenuCategoriesItems[category];
    return (
      <div
        className={b('item', { isSelected: visibleMenu[category] })}
        style={data.color && { color: data.color }}
        onClick={() => {
          const element = document.getElementById(category);
          if (element) element.scrollIntoView({ behavior: 'smooth' });
        }}
      >
        {data.text[lang]}
      </div>
    );
  }
}
