import { ILanguage } from '../languages';
import { IMenuCategoryID } from '../menuCategoriesItems';

export type IText = { [Property in ILanguage]: string | JSX.Element };

export const categories: { [Property in IMenuCategoryID]: IText } = {
  entrance: {
    polish: 'Przystawki',
  },
  tacos_quesadilla_burritos: {
    polish: (
      <div>
        Tacos
        <br />
        Quesadillas
        <br />
        Burritos
      </div>
    ),
  },
  secondary: {
    polish: 'Drugie Dania',
  },
  souses: {
    polish: 'Salsas',
  },
  desserts: {
    polish: 'Desery',
  },
  drinks: {
    polish: 'Napoje',
  },
} as const;
