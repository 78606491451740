import { PureComponent } from 'react';
import block from 'bem-cn';
import logo from '../../assets/img/Logo.png';
import './header.scss';
import headerText from '../../assets/text/heeader.json';
import {IState} from "../../redux/mainReducer";
import {Link} from "react-router-dom";

const b = block('header');
export interface IHeaderProps {
    lang: IState['lang'];
}

export class HeaderComponent extends PureComponent<IHeaderProps> {
  static defaultProps: IHeaderProps = {
      lang: "polish",
  };
  render() {
    const { lang } = this.props;
    return (
      <nav className={b()}>
        <Link className={b('menu_item')} to='/about'>{headerText.aboutUs[lang]}</Link>
        <Link className={b('menu_item')} to='/menu'>{headerText.menu[lang]}</Link>
        <img className={b('logo')} src={logo} alt="logo"></img>
        <Link className={b('menu_item')} to='/gallery'>{headerText.gallery[lang]}</Link>
        <Link className={b('menu_item')} to='/contacts'>{headerText.contact[lang]}</Link>
      </nav>
    );
  }
}
