import { categories, IText } from './texts/categories';
import {
  entradasMenu,
  tacosQuesadillaBurritosMenu,
  secondaryMenu,
  freshWaterMenu,
  freshJuiceMenu,
  desertMenu,
  drinksMenu,
  hotDrinksMenu,
  soupsMenu,
  sousesMenu,
  IMenuContent,
} from './menu_items';
import { MenuContentClasses } from '../components/Menu';

export type IMenu = { [Property in IMenuCategoryID]: IMenuCategory };

export interface IMenuCategory {
  type: keyof typeof MenuContentClasses;
  color?: any;
  text: IText;
  content: IMenuContent[];
}
export type IMenuCategoryID = keyof typeof MenuCategories;

export const MenuCategories = {
  entrance: 'entrance',
  tacos_quesadilla_burritos: 'tacos_quesadilla_burritos',
  secondary: 'secondary',
  souses: 'souses',
  drinks: 'drinks',
  desserts: 'desserts',
} as const;

export const MenuCategoriesItems: IMenu = {
  entrance: {
    text: categories.entrance,
    type: 'MenuContent',
    content: [entradasMenu],
  },
  tacos_quesadilla_burritos: {
    text: categories.tacos_quesadilla_burritos,
    type: 'TQBContent',
    content: [tacosQuesadillaBurritosMenu],
  },
  secondary: {
    text: categories.secondary,
    type: 'MenuContent',
    content: [secondaryMenu, soupsMenu],
  },
  souses: {
    text: categories.souses,
    type: 'MenuContent',
    content: [sousesMenu],
  },
  drinks: {
    text: categories.drinks,
    type: 'MenuContent',
    content: [freshWaterMenu, freshJuiceMenu, drinksMenu, hotDrinksMenu],
  },
  desserts: {
    text: categories.desserts,
    type: 'MenuContent',
    content: [desertMenu],
  },
};
