import { IAction } from './actions';
import { Reducer } from 'redux';
import { ILanguage } from '../constants/languages';
import { ISection } from '../constants/sections';
import { MenuCategories } from '../constants/menuCategoriesItems';

export interface IState {
  lang: ILanguage;
  section: ISection;
  visibleMenuElements: { [Property in keyof typeof MenuCategories]: boolean };
  mapsLoaded: boolean;
}

export const initialState: IState = {
  lang: 'polish',
  section: 'menu',
  mapsLoaded: false,
  visibleMenuElements: {
    entrance: true,
    tacos_quesadilla_burritos: false,
    secondary: false,
    drinks: false,
    desserts: false,
    souses: false,
  },
};

export const mainReducer = (action: IAction, state?: IState): IState => {
  const { type } = action;
  if (!state) return initialState;
  switch (type) {
    case 'LANGUAGE_CHANGE': {
      const data = action.data as IState['lang'];
      return { ...state, lang: data };
    }
    case 'ON_SECTION_CHANGE': {
      const data = action.data as IState['section'];
      return { ...state, section: data };
    }
    case 'ON_MENU_VISIBILE': {
      const data = action.data as Partial<IState['visibleMenuElements']>;
      return {
        ...state,
        visibleMenuElements: { ...state.visibleMenuElements, ...data },
      };
    }
    default: {
      return state;
    }
  }
};

export const rootReducer: Reducer<IState, IAction> = (action, state) =>
  mainReducer(state, action);
