import { AboutComponent, IAboutProps } from './About';
import { IState } from '../../redux/mainReducer';
import { getLang } from '../../redux/selector';
import { connect } from 'react-redux';

const mapStateToProps = (state: IState): IAboutProps => ({
  lang: getLang(state),
});

export const About = connect(mapStateToProps)(AboutComponent);
