import maiz from '../assets/img/MAIZ.png';
import vege from '../assets/img/VEGE.png';
import one_chilli from '../assets/img/one_chilli.png';
import two_chilli from '../assets/img/two_chilli.png';
import three_chilli from '../assets/img/three_chilli.png';
import four_chilli from '../assets/img/four_chilli.png';
export const MAIZ = <img className=".menu_icon" src={maiz} />;
export const VEGE = <img className=".menu_icon" src={vege} />;
export const ONE_CHILLI = <img className=".menu_icon" src={one_chilli} />;
export const TWO_CHILLI = <img className=".menu_icon" src={two_chilli} />;
export const THREE_CHILLI = <img className=".menu_icon" src={three_chilli} />;
export const FOUR_CHILLI = <img className=".menu_icon" src={four_chilli} />;

export const threeHundred = (
  <span className="menu_icon_ml">
    300
    <br />
    ml
  </span>
);

export type IIcon =
  | typeof MAIZ
  | typeof VEGE
  | typeof threeHundred
  | typeof ONE_CHILLI
  | typeof TWO_CHILLI
  | typeof THREE_CHILLI
  | typeof FOUR_CHILLI;
