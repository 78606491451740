import { PureComponent } from 'react';
import Carousel from 'react-gallery-carousel';
import 'react-gallery-carousel/dist/index.css';
import block from 'bem-cn';

import './gallery.scss';

const b = block('gallery');

const photos = [
  { src: '/photos/IMG_0363.jpg' },
  { src: '/photos/IMG_0372.jpg' },
  { src: '/photos/IMG_0385.jpg' },
  { src: '/photos/IMG_0391.jpg' },
  { src: '/photos/IMG_0415.jpg' },
  { src: '/photos/IMG_0439.jpg' },
  { src: '/photos/IMG_0455.jpg' },
  { src: '/photos/IMG_0465.jpg' },
  { src: '/photos/IMG_0481.jpg' },
  { src: '/photos/IMG_0488.jpg' },
  { src: '/photos/IMG_0498.jpg' },
  { src: '/photos/IMG_0520.jpg' },
  { src: '/photos/IMG_0549.jpg' },
  { src: '/photos/IMG_0588.jpg' },
];

const iconLeft = <span className={b('left-right-button')}>{'<'}</span>;
const iconRight = <span className={b('left-right-button')}>{'>'}</span>;

export class GalleryComponent extends PureComponent {
  render() {
    return (
      <div className={b()}>
        <Carousel
          images={photos}
          isLoop={true}
          hasThumbnails={false}
          objectFit={'scale-down'}
          className={b('carousel').toString()}
          leftIcon={iconLeft}
          rightIcon={iconRight}
          hasIndexBoard={false}
          hasMediaButton={false}
        />
      </div>
    );
  }
}
