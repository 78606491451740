import { createElement, PureComponent } from 'react';
import _ from 'lodash';
import { block } from 'bem-cn';
import './menu.scss';
import {
  MenuCategories,
  MenuCategoriesItems,
} from '../../constants/menuCategoriesItems';
import { MenuContent, TQBContent } from './MenuContent';
import { Categories } from './index';

const b = block('menu');
export const MenuContentClasses = { TQBContent, MenuContent };

export class MenuComponent extends PureComponent {
  render() {
    return (
      <div className={b()}>
        <Categories />
        <div id="menu-container" className={b('container')}>
          {_.map(MenuCategories, (item) =>
            createElement(MenuContentClasses[MenuCategoriesItems[item].type], {
              id: item,
              key: item,
            }),
          )}
        </div>
      </div>
    );
  }
}
