import { bindActionCreators } from 'redux';
import { onMenuVisible } from '../../../redux/actions';
import { connect, MapStateToProps } from 'react-redux';
import {
  IContentActions,
  IContentOwnProps,
  IContentProps,
  MenuContentComponent,
} from './MenuContent';
import { IState } from '../../../redux/mainReducer';
import { getLang } from '../../../redux/selector';
import {TQBContentComponent} from "./TQBContent";

const mapDispatchToProps = (dispath: any): IContentActions =>
  bindActionCreators(
    {
      onVisible: onMenuVisible,
    },
    dispath,
  );
const mapStateToProps: MapStateToProps<
  IContentProps,
  IContentOwnProps,
  IState
> = (state, ownProps) => ({ ...ownProps, lang: getLang(state) });

export const MenuContent = connect(
  mapStateToProps,
  mapDispatchToProps,
)(MenuContentComponent);
export const TQBContent = connect(
  mapStateToProps,
  mapDispatchToProps,
)(TQBContentComponent);
