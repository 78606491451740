import { Action } from 'redux';
import { ISection } from '../constants/sections';
import { ILanguage } from '../constants/languages';
import { IMenuCategoryID } from '../constants/menuCategoriesItems';
import { IState } from './mainReducer';

export const LANGUAGE_CHANGE = 'LANGUAGE_CHANGE' as const;
export const ON_SECTION_CHANGE = 'ON_SECTION_CHANGE' as const;
export const ON_MENU_VISIBILE = 'ON_MENU_VISIBILE' as const;

export interface IAction<T = any> extends Action {
  type:
    | typeof LANGUAGE_CHANGE
    | typeof ON_SECTION_CHANGE
    | typeof ON_MENU_VISIBILE;
  data: T | undefined;
}

export const onLanguageChange = (lang: ILanguage): IAction<ILanguage> => ({
  type: LANGUAGE_CHANGE,
  data: lang,
});

export const onSectionChange = (section: ISection): IAction<ISection> => ({
  type: ON_SECTION_CHANGE,
  data: section,
});

export const onMenuVisible = (
  id: IMenuCategoryID,
  isVisible: boolean,
): IAction<Partial<IState['visibleMenuElements']>> => ({
  type: ON_MENU_VISIBILE,
  data: { [id]: isVisible },
});
