import { IState } from '../../redux/mainReducer';
import { getLang } from '../../redux/selector';
import { connect } from 'react-redux';
import { FooterComponent, IFooterProps } from './FooterComponent';

const mapStateToProps = (state: IState): IFooterProps => ({
  lang: getLang(state),
});

export const Footer = connect(mapStateToProps)(FooterComponent);
