import { PureComponent } from 'react';
import block from 'bem-cn';
import { IState } from '../../redux/mainReducer';

import './footer.scss';

const b = block('footer');
export interface IFooterProps {
  lang: IState['lang'];
}

export class FooterComponent extends PureComponent {
  render() {
    return (
      <div className={b()}>
        <span>
          <a
            className={b('links', { icon: 'fb' })}
            href="https://www.facebook.com/Santa-Catrina-Cocina-Mexicana-108218978469824/"
            target="_blank"
          />
          Santa Catrina
        </span>
        <span>
          <a
            className={b('links', { icon: 'gm' })}
            href="https://goo.gl/maps/JENCsSpB8hxowUt39"
            target="_blank"
          ></a>
          <div>ul. Radna 14, 00-064 Warszawa</div>
        </span>
        <span>
          <a
            className={b('links', { icon: 'phone' })}
            href="tel:(+48)796-617-515"
          />
          (+48) 796 617 515
        </span>
        <span>
          <a
            className={b('links', { icon: 'email' })}
            href="mailto:(+48)796-617-515"
          />
          kontakt@santacatrina.pl
        </span>
      </div>
    );
  }
}
