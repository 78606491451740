import {
  MAIZ,
  IIcon,
  VEGE,
  ONE_CHILLI,
  TWO_CHILLI,
  THREE_CHILLI,
  FOUR_CHILLI,
} from './Icons';
import { IText } from './texts/categories';
import { block } from 'bem-cn';

export const totopos = <span className="totopos">‘’totopos’’</span>;
export const chipotle = <span className="totopos">‘’chipotle’’</span>;
export const tomatillo = <span className="tomatillo">‘’tomatillo’’</span>;
export const tortille = <span className="tortille">‘’tortille’’</span>;
export const tortillami = <span className="tortillami">‘’tortillami’’</span>;
export const gallo = <span className="gallo">‘’pico de gallo’’</span>;
export const jalapeno = <span className="jalapeno">‘’jalapeño’’</span>;
export const chorizo = <span className="chorizo">‘’chorizo’’</span>;
export const achiote = <span className="achiote">‘’achiote’’</span>;
export const adobo = <span className="adobo">‘’adobo’’</span>;
export const poblano = <span className="poblano">‘’mole poblano’’</span>;
export const pasilla = <span className="pasilla">‘’pasilla’’</span>;
export const puya = <span className="puya">‘’puya’’</span>;
export const habanero = <span className="habanero">‘’habanero’’</span>;
export const carolina = (
  <span className="carolina_reaper">‘’carolina reaper’’</span>
);

export const zl = <span className="zl">zl</span>
export const ml = <span className="zl">ml</span>

const b = block('menu__content__item');

export type IMenuContent = { title: IText; items: (IMenuItem | undefined)[] };

export const soupsMenu: IMenuContent = {
  title: { polish: 'Zupas' },
  items: [
    {
      title: { polish: 'Sopa Azteca' },
      price: 27,
      icon: [MAIZ, VEGE],
      text: {
        polish: (
          <span>
            Zupa z pomidorami z papryką chile {pasilla} ze smażonymi paseczkami
            z kukurydzianej {tortille}
          </span>
        ),
      },
    },
    {
      title: { polish: 'Sopa Pozole' },
      price: 38,
      icon: [MAIZ],
      text: {
        polish: (
          <span>
            Zupa z białą kukurydzą i wieprzowiną, tostadas, awokado, sałatą
            lodową, rzodkiewką i limonką.
          </span>
        ),
      },
    },
  ],
};

export const sousesMenu: IMenuContent = {
  title: { polish: 'Salsy' },
  items: [
    {
      price: <span></span>,
      title: { polish: 'Salsa roja' },
      text: { polish: <span>grillowane pomidory i chile {jalapeno}</span> },
    },
    {
      price: <span></span>,
      title: { polish: 'Salsa verde' },
      icon: [ONE_CHILLI],
      text: {
        polish: (
          <span>
            zielone pomidory {tomatillo}, kolendra i chile {jalapeno}
          </span>
        ),
      },
    },
    {
      price: <span />,
      title: { polish: 'Pico de gallo' },
      text: {
        polish: (
          <span>
            posiekane pomidory, cebula, kolendra, {jalapeno} i limonka
          </span>
        ),
      },
    },
    {
      price: <span />,
      icon: [TWO_CHILLI],
      title: { polish: 'Salsa chipotle' },
      text: {
        polish: <span>pomidory grillowane i papryka chile {chipotle}</span>,
      },
    },
    {
      price: <span />,
      icon: [TWO_CHILLI],
      title: { polish: 'Salsa macha' },
      text: {
        polish: (
          <span>
            orzechy ziemne, oliwa, papryka {puya} i papryka {pasilla}
          </span>
        ),
      },
    },
    {
      price: <span />,
      icon: [THREE_CHILLI],
      title: { polish: 'Salsa habanero' },
      text: {
        polish: (
          <span>
            {tomatillo}, pomidory, ananas i {habanero}
          </span>
        ),
      },
    },
    {
      price: <span />,
      icon: [FOUR_CHILLI],
      title: { polish: 'Salsa de Jamaica' },
      text: {
        polish: <span>hibiskus, jabłko, {carolina}</span>,
      },
    },
    {
      price: <span />,
      title: { polish: '' },
      text: { polish: 'kwaśna śmietana' },
    },
  ],
};

export const hotDrinksMenu: IMenuContent = {
  title: { polish: 'Napoje Ciepłe' },
  items: [
    {
      price: 12,
      text: { polish: '' },
      title: { polish: 'Espresso' },
    },
    {
      price: 12,
      text: { polish: '' },
      title: { polish: 'Americano' },
    },
    {
      price: 14,
      text: { polish: '' },
      title: { polish: 'Capuccino' },
    },
    {
      price: 16,
      text: { polish: '' },
      title: { polish: 'Latte' },
    },
    {
      price: 16,
      text: { polish: '' },
      title: { polish: 'Cafe de olla' },
    },
    {
      price: 10,
      text: { polish: '' },
      title: { polish: 'Herbata' },
    },
  ],
};

export const freshWaterMenu: IMenuContent = {
  title: { polish: 'Orzeźwiające napooje' },
  items: [
    {
      title: {
        polish: 'Agua de Limon',
      },
      price: (
        <span className={b('water-price')}>
          <span>300{ml}</span>
          <span>16{zl}</span>
        </span>
      ),
      text: {
        polish: <span>Napój cytrynowy.</span>,
      },
    },
    {
      title: {
        polish: 'Agua de Jamaica',
      },
      price: (
        <span className={b('water-price')}>
          <span>300{ml}</span>
          <span>16{zl}</span>
        </span>
      ),
      text: {
        polish: <span>Napój z kwiatów hibiskusa</span>,
      },
    },
    {
      title: {
        polish: 'Agua de Horchata',
      },
      price: (
        <span className={b('water-price')}>
          <span>300{ml}</span>
          <span>16{zl}</span>
        </span>
      ),
      text: {
        polish: <span>Napój ryżowy z{ml}ekiem i cynamonem</span>,
      },
    },
    {
      title: {
        polish: 'Te helado',
      },
      price: (
        <span className={b('water-price')}>
          <span>300{ml}</span>
          <span>16{zl}</span>
        </span>
      ),
      text: {
        polish: <span>Mrożona Herbata</span>,
      },
    },
    {
      title: {
        polish: 'Dzbanek Agua fresca',
      },
      price: (
        <span className={b('water-price')}>
          <span>1 litr</span>
          <span>40{zl}</span>
        </span>
      ),
      text: {
        polish: <span>napoje domowe</span>,
      },
    },
  ],
};

export const desertMenu: IMenuContent = {
  title: { polish: 'Desery' },
  items: [
    {
      title: { polish: 'Churros' },
      price: 22,
      text: {
        polish:
          'Smażone ciasto z cukrem cynamonowym i sosem czekoladowym lub karmelowym',
      },
    },
    {
      title: { polish: 'Churros con helado' },
      price: 24,
      text: {
        polish:
          'Lody waniliowe z ‘’churrosami’’ z sosem czekoladowym i orzechami',
      },
    },
    {
      title: { polish: 'Tres leches con cajeta' },
      price: 24,
      text: {
        polish:
          'Biszkopt waniliowy z trzema rodzajami {ml}eka, tapioca i sosem karmelowym',
      },
    },
    {
      title: { polish: 'Brownie' },
      price: 23,
      text: { polish: 'Ciasto czekoladowo-orzechowe z lodami waniliowymi' },
    },
  ],
};

export const drinksMenu: IMenuContent = {
  title: { polish: 'Napoje w butelce' },
  items: [
    {
      title: { polish: 'Jarritos' },
      price: (
        <span className={b('water-price')}>
          <span>370{ml}</span>
          <span>16{zl}</span>
        </span>
      ),
      text: {
        polish:
          'Limonka, ananas, mandarynka, mango, grejpfrut, cola z meksyku, guayaba',
      },
    },
    {
      title: {
        polish: 'Cola, Fanta, Sprite',
      },
      price: (
        <span className={b('water-price')}>
          <span>250{ml}</span>
          <span>10{zl}</span>
        </span>
      ),
      text: {
        polish: 'Coca Cola, Coca Cola zero, Fanta, Sprite',
      },
    },
    {
      title: { polish: 'Cappy' },
      price: (
        <span className={b('water-price')}>
          <span>250{ml}</span>
          <span>10{zl}</span>
        </span>
      ),
      text: {
        polish: 'jabłko, czarna porzeczka, grejpfrut, pomarańcza, pomidor',
      },
    },
    {
      title: { polish: 'Kropla Beskidu' },
      price: (
        <span className={b('water-price')}>
          <span>330{ml}</span>
          <span>8{zl}</span>
        </span>
      ),
      text: { polish: 'Kropla Beskidu nie gazowana' },
    },
    {
      title: { polish: 'Kropla Delice' },
      price: (
        <span className={b('water-price')}>
          <span>330{ml}</span>
          <span>8{zl}</span>
        </span>
      ),
      text: { polish: 'Kropla Delice gazowana' },
    },
  ],
};

export const freshJuiceMenu: IMenuContent = {
  title: { polish: 'Soki Świeże Wyciskane' },
  items: [
    {
      title: {
        polish: 'NARANJA/TORONJA/MIX',
      },
      price: (
        <span className={b('water-price')}>
          <span>300{ml}</span>
          <span>18{zl}</span>
        </span>
      ),
      text: {
        polish: <span>Pomarańcza / Różowy grejpfrut / Mieszanka soków</span>,
      },
    },
  ],
};

export const secondaryMenu: IMenuContent = {
  title: { polish: 'Drugie Dania' },
  items: [
    {
      title: {
        polish: 'Chilaquiles',
      },
      price: 48,
      icon: [MAIZ],
      text: {
        polish: (
          <span>
            Czipsy {totopos} kukurydziane w sosie pomidorowo-chipotle, z
            wołowiną grillowaną, jajkiem sadzonym, awokado i pastą fasolową.
          </span>
        ),
      },
    },
    {
      title: {
        polish: 'Fajitas de Pollo',
      },
      price: 42,
      text: {
        polish: (
          <span>
            Grillowany kurczak z papryką i cebulą, podawane z {tortillami}
            pszennymi (4 szt) i salsą.
          </span>
        ),
      },
    },
    {
      title: {
        polish: 'Fajitas de Cerdo',
      },
      price: 46,
      text: {
        polish: (
          <span>
            Grillowana polędwica wieprzowa z papryką i cebulą, podawana z
            {tortillami} pszennymi (4szt) i salsą.
          </span>
        ),
      },
    },
    {
      title: {
        polish: 'Fajitas de Res',
      },
      price: 56,
      text: {
        polish: (
          <span>
            Grillowany steak wołowy z papryką i cebulą, podawany z {tortillami}
            pszennymi(4szt) i salsą.
          </span>
        ),
      },
    },
    {
      title: {
        polish: 'Fajitas especial',
      },
      price: 60,
      text: {
        polish: (
          <span>
            Grillowany steak wołowy i kurczak z papryką i cebulą, podawane z{' '}
            {tortillami} pszennymi (4 szt), salsy i guacamole.
          </span>
        ),
      },
    },
    {
      title: {
        polish: 'Plato de Carnitas',
      },
      price: 48,
      text: {
        polish: (
          <span>
            Duszona wieprzowina w pomarańczach, z {tortillami} kukurydzianymi
            (3szt.), fasolą, {gallo}, cebulą, rzodkiewką i guacamole.
          </span>
        ),
      },
    },
    {
      title: {
        polish: 'Plato Poblano',
      },
      price: 48,
      text: {
        polish: (
          <span>
            Trzy {tortille} kukurydziane z kurczakiem i sosem {poblano} z
            suszonych papryk i czekolady oraz fasolą, grillowanymi warzywami i
            ryżem meksykańskim.
          </span>
        ),
      },
    },
  ],
};

export interface IMenuItem {
  title: IText;
  text: IText;
  icon?: IIcon | IIcon[];
  price: number | JSX.Element;
}

export const entradasMenu: IMenuContent = {
  title: { polish: 'Przystawki' },
  items: [
    {
      title: {
        polish: 'Tamal Verde',
      },
      price: 25,
      icon: [MAIZ],
      text: {
        polish: (
          <span>
            Ciasto kukurydziane na liściu z kolby kukurydzy zawijane z
            wieprzowiną w sosie z zielonych pomidorów {tomatillo} z serem białym
            i czerwoną cebulą.
          </span>
        ),
      },
    },
    {
      title: {
        polish: 'Tamal De Mole',
      },
      price: 25,
      icon: [MAIZ],
      text: {
        polish: (
          <span>
            Ciasto kukurydziane na liściu z kolby kukurydzy z kurczakiem w sosie
            z czekolady i suszonych papryk z serem białym.
          </span>
        ),
      },
    },
    {
      title: {
        polish: 'Empanadas',
      },
      price: 22,
      icon: [MAIZ, VEGE],
      text: {
        polish: (
          <span>
            Pierogi z masy kukurydzianej faszerowane pastą z bobem i chile{' '}
            {chipotle}, polane śmietaną i kolendrą (3szt).
          </span>
        ),
      },
    },
    {
      title: {
        polish: 'Guacamole con Totopos',
      },
      price: 36,
      icon: [MAIZ, VEGE],
      text: {
        polish: (
          <span>
            Pasta z awokado z pomidorami, kolendrą i limonką z chrupiącymi
            czipsami kukurydzianymi {totopos}
          </span>
        ),
      },
    },
    {
      title: {
        polish: 'Totopos con Frijoles y Queso',
      },
      price: 34,
      icon: [MAIZ, VEGE],
      text: {
        polish: (
          <span>
            Czipsy kukurydziane {totopos} z fasolą i papryczką chili {jalapeno}{' '}
            z sosem serowym, posypane sałatką z czerwonych pomidorów, cebuli i
            kolędry
            {gallo}.
          </span>
        ),
      },
    },
    {
      title: {
        polish: 'Coctel de Camaron',
      },
      price: 38,
      icon: [MAIZ],
      text: {
        polish: (
          <span>
            Krewetki w sosie pomidorowo-pomarańczowym, z pomidorami, chile{' '}
            {jalapeno}, awokado i czipsami z kawałków tortilli {totopos}.
          </span>
        ),
      },
    },
    {
      title: { polish: 'Molletes con Chorizo' },
      price: 26,
      text: {
        polish: (
          <span>
            Zapiekanki z fasolą, kiełbasą {chorizo}, ser i sałatką z czerwonych
            pomidorów, cebuli i kolędry {gallo}.
          </span>
        ),
      },
    },
    undefined,
    {
      price: 78,
      title: { polish: 'Antojitos Combo' },
      text: {
        polish: (
          <span>
            Zestaw meksykańskich streetfoodowych przystawek dla dwóch osób.
            <ul>
              <li>
                &nbsp;&nbsp;&nbsp;&nbsp;- Tacos de cochinita, empanadas,
                tostadas de maiz blanco, molletes con chorizo i salsas (wege).
              </li>
              <li>
                {VEGE} - Tacos de jaca, empanadas, tostadas de maiz blanco,
                molletes con jaca i salsas
              </li>
            </ul>
          </span>
        ),
      },
    },
    {
      price: 78,
      title: { polish: 'Fritangas Combo' },
      text: {
        polish: (
          <span>
            Zestaw meksykańskich streetfoodowych przystawek dla dwóch osób.
            <ul>
              <li>
                &nbsp;&nbsp;&nbsp;&nbsp;- Tacos fritos de papa con chorizo,
                empanadas, quesadilla de cerdo, papas fritas i salsas.
              </li>
              <li>
                {VEGE} - Tacos de papa con verduras, empanadas, quesadilla de
                jackfruit, papas fritas i salsas
              </li>
            </ul>
          </span>
        ),
      },
    },
  ],
};

export const tacosQuesadillaBurritosMenu: IMenuContent = {
  title: { polish: 'Tacos Quesadillas Burritos' },
  items: [
    {
      title: { polish: 'Cochinita' },
      price: (
        <span className={b('tqb-price')}>
          <span>39{zl}</span>
          <span>37{zl}</span>
          <span>39{zl}</span>
        </span>
      ),
      text: {
        polish: (
          <span>
            Wieprzowina marynowana w pomarańczach i naturalnym barwniku z nasion
            drzewa tropikalnego owocu {achiote}, serwowane z marynowaną cebulą.
          </span>
        ),
      },
    },
    {
      title: { polish: 'Barbacoa' },
      price: (
        <span className={b('tqb-price')}>
          <span>42{zl}</span>
          <span>40{zl}</span>
          <span>42{zl}</span>
        </span>
      ),
      text: {
        polish: (
          <span>
            Wołowina marynowana w marynacie z {adobo} z suszonych papryk
          </span>
        ),
      },
    },
    {
      title: { polish: 'Gobernador' },
      price: (
        <span className={b('tqb-price')}>
          <span>49{zl}</span>
          <span>47{zl}</span>
          <span>49{zl}</span>
        </span>
      ),
      text: {
        polish: <span>Krewetki, pasta fasolowa, ser i papryka.</span>,
      },
    },
    {
      title: { polish: 'Jaca adobada' },
      price: (
        <span className={b('tqb-price')}>
          <span>37{zl}</span>
          <span>35{zl}</span>
          <span>37{zl}</span>
        </span>
      ),
      text: {
        polish: (
          <span>
            Jackfruit marynowane w naturalnym barwniku z nasion drzewa
            tropikalnego owocu {achiote} i ananas
          </span>
        ),
      },
    },
    {
      title: { polish: 'Maiz blanco' },
      price: (
        <span className={b('tqb-price')}>
          <span>37{zl}</span>
          <span>35{zl}</span>
          <span>37{zl}</span>
        </span>
      ),
      text: {
        polish: <span>Biała kukurydza, cukinia, papryka i cebula biała</span>,
      },
    },
  ],
};
