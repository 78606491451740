import { ContactsComponent, IContactProps } from './Contacts';
import { connect } from 'react-redux';
import { IState } from '../../redux/mainReducer';
import { getLang, isGoogleMapsLoaded } from '../../redux/selector';

const mapStateToProps = (state: IState): IContactProps => ({
  lang: getLang(state),
  isGoogleMapsLoaded: isGoogleMapsLoaded(state),
});

export const Contacts = connect(mapStateToProps)(ContactsComponent);
