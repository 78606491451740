import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import reduxSaga from 'redux-saga';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { saga } from './redux';
import { configureStore } from '@reduxjs/toolkit';
import { initialState, rootReducer } from './redux/mainReducer';
import {BrowserRouter, HashRouter, Route, Routes} from 'react-router-dom';
import { Menu } from './components/Menu';
import { Gallery } from './components/Gallery';
import { About } from './components/AboutUs';
import { Contacts } from './components/Contacts';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

const isDebug = process.env.NODE_ENV === 'development';

const createReduxStore = () => {
  const middleware = reduxSaga();
  const store = configureStore({
    middleware: [middleware],
    devTools: isDebug,
    preloadedState: initialState,
    reducer: rootReducer,
  });
  middleware.run(saga);
  return store;
};

root.render(
  <React.StrictMode>
    <Provider store={createReduxStore()}>
      <HashRouter>
        <Routes>
          <Route path="/" element={<App />}>
            <Route path="/" element={<About />} />
            <Route path="about" element={<About />} />
            <Route path="menu" element={<Menu />} />
            <Route path="gallery" element={<Gallery />} />
            <Route path="contacts" element={<Contacts />} />
          </Route>
        </Routes>
      </HashRouter>
    </Provider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
