import { HeaderComponent, IHeaderProps } from './HeaderComponent';
import { connect } from 'react-redux';
import { IState } from '../../redux/mainReducer';
import { getLang } from '../../redux/selector';

const mapStateToProps = (state: IState): IHeaderProps => ({
  lang: getLang(state),
});

export const Header = connect(mapStateToProps)(HeaderComponent);
