import { PureComponent } from 'react';
import { GoogleMap, useLoadScript, MarkerF } from '@react-google-maps/api';

import block from 'bem-cn';
import { ILanguage } from '../../constants/languages';

import './contacts.scss';

const b = block('contacts');

export interface IContactProps {
  lang: ILanguage;
  isGoogleMapsLoaded: boolean;
}

function Map() {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: 'AIzaSyD69_ym44jikg5qMDNhoE-687eQr_FbZI0',
  });
  return isLoaded ? (
    <GoogleMap
      zoom={16}
      center={{ lat: 52.2407776296725, lng: 21.02320595104715 }}
      mapContainerClassName={b('map')}
      options={{ clickableIcons: false, mapTypeControl: false }}
    >
      <MarkerF
        position={{ lat: 52.2407776296725, lng: 21.02320595104715 }}
        label="test"
        icon={'/favicon-32x32.png'}
      />
    </GoogleMap>
  ) : null;
}

export class ContactsComponent extends PureComponent<IContactProps> {
  render() {
    const currentMonth = new Date().getMonth();
    return (
      <div className={b()}>
        <Map />
        <div className={b('info')}>
          <div className={b('address')}>
            <div className={b('title')}>Dane kontaktowe</div>
            <br />
            <ul>
              <li>00-064 Warszawa</li>
              <li>ul. Radna 14</li>
              <br />
              <li>(+48) 796 617 515</li>
              <li>kontakt@santacatrina.pl</li>
            </ul>
            <br />
          </div>
          <div className={b('open')}>
            <div className={b('title')}>Godziny otwarcia</div>
            <br />
            <ul>
              <li>{currentMonth > 9 || currentMonth < 4 ? 'Wto' : 'Pon'} - Czw: 12:00 - 21:00</li>
              <li>Piątek, Sobota: 12:00 - 22:00</li>
              <li>Niedziela: 12:00 - 21:00</li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}
