import { PureComponent } from 'react';
import _ from 'lodash';
import block from 'bem-cn';
import { MenuContentItem } from './MenuContentItem';
import { ILanguage } from '../../../constants/languages';
import {
  IMenuCategoryID,
  MenuCategoriesItems,
} from '../../../constants/menuCategoriesItems';
import './content.scss';
import VisibilitySensor from 'react-visibility-sensor';
import { onMenuVisible } from '../../../redux/actions';

const b = block('menu__content');

export interface IContentOwnProps {
  id: IMenuCategoryID;
}

interface IContentState {
  container: HTMLElement | null;
}

export interface IContentActions {
  onVisible: typeof onMenuVisible;
}

export interface IContentProps extends IContentOwnProps {
  lang: ILanguage;
}

export class MenuContentComponent extends PureComponent<
  IContentProps & IContentActions,
  IContentState
> {
  state: IContentState = {
    container: null,
  };

  onVisibilityChange = (isVisible: boolean) => {
    const { onVisible, id } = this.props;
    onVisible(id, isVisible);
  };

  componentDidMount() {
    this.setState({ container: document.getElementById('menu-container') });
  }

  render() {
    const { lang, id } = this.props;
    const category = MenuCategoriesItems[id];
    return (
      <VisibilitySensor
        onChange={this.onVisibilityChange}
        containment={this.state.container}
        resizeCheck={true}
        partialVisibility={true}
      >
        <div id={id} className={b({ type: 'default', id })}>
          {_.map(category.content, (contentItem, contentKey) => (
            <div className={b('section')}>
              <div className={b('title')} key={contentKey}>
                {contentItem.title[lang]}
              </div>
              <div className={b('items')}>
                {_.map(contentItem.items, (item, key) => (
                  <MenuContentItem lang={lang} item={item} key={key} />
                ))}
                {contentItem.items.length % 2 !== 0 && <div />}
              </div>
            </div>
          ))}
        </div>
      </VisibilitySensor>
    );
  }
}
