import {CategoriesComponent, ICategoriesProps} from './Categories';
import {IState} from "../../../redux/mainReducer";
import {getLang, visibleMenuElements} from "../../../redux/selector";
import {connect} from "react-redux";

const mapStateToProps = (state: IState): ICategoriesProps => ({
    lang: getLang(state),
    visibleMenu: visibleMenuElements(state),
});

export const Categories = connect(mapStateToProps)(CategoriesComponent);
