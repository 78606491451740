import { PureComponent } from 'react';
import _ from 'lodash';
import { block } from 'bem-cn';
import { ILanguage } from '../../../constants/languages';
import {IMenuItem, zl} from '../../../constants/menu_items';

const b = block('menu__content__item');

export interface IItemProps {
  lang: ILanguage;
  item: IMenuItem | undefined;
}

export class MenuContentItem extends PureComponent<IItemProps> {
  render() {
    const { item, lang } = this.props;
    if(item === undefined)
    {
        return (<span></span>)
    }

    return (
      <div className={b()}>
        <div className={b('header')}>
          <div className={b('title')}>{item.title[lang]}</div>
          <div className={b('price')}>{item.price}{_.isNumber(item.price) && <span className="zl">zl</span>}</div>
        </div>
        <div className={b('description')}>
          <div className={b('icons')}>{item.icon}</div>
          <div className={b('text')}>{item.text[lang]}</div>
        </div>
      </div>
    );
  }
}
