import { PureComponent } from 'react';
import block from 'bem-cn';
import './background.scss';
const b = block('background');

export class Background extends PureComponent {
  render() {
    return (
      <div className={b()}>
        <div className={b('right')}>
          <div className={b('top')}>
            <div className={b('image', { top: true })}></div>
            <div className={b('image', { bottom: true })}></div>
          </div>
          <div className={b('bottom')}>
            <div className={b('image', { top: true })}></div>
            <div className={b('image', { bottom: true })}></div>
          </div>
        </div>
        <div className={b('left')}>
          <div className={b('top')}>
            <div className={b('image', { top: true })}></div>
            <div className={b('image', { bottom: true })}></div>
          </div>
          <div className={b('bottom')}>
            <div className={b('image', { top: true })}></div>
            <div className={b('image', { bottom: true })}></div>
          </div>
        </div>
      </div>
    );
  }
}
