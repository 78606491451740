import { PureComponent } from 'react';
import { ILanguage } from '../../constants/languages';
import { aboutUs } from '../../constants';
import block from "bem-cn";

import './about.scss';

const b = block('about')

export interface IAboutProps {
  lang: ILanguage;
}

export class AboutComponent extends PureComponent<IAboutProps> {
  render() {
    const { lang } = this.props;
    return <div className={b()}><div className={b('text')}>{aboutUs[lang]}</div></div>;
  }
}
