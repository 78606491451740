import React, { PureComponent, UIEventHandler } from 'react';
import './App.scss';
import block from 'bem-cn';
import { Background } from './components/Background';
import { Header } from './components/Header';
import { Outlet } from 'react-router-dom';
import { Footer } from './components/Footer';

const b = block('app');

class App extends PureComponent {
  render() {
    return (
      <>
        <Background />
        <div className={b()}>
          <Header />
          <Outlet />
          <Footer />
        </div>
      </>
    );
  }
}

export default App;
