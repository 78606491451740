import { PureComponent } from 'react';
import _ from 'lodash';
import block from 'bem-cn';
import { CategoryItem } from './CategoryItem';
import { MenuCategories } from '../../../constants/menuCategoriesItems';
import { ILanguage } from '../../../constants/languages';
import { visibleMenuElements } from '../../../redux/selector';

export const b = block('menu__category');

export interface ICategoriesProps {
  lang: ILanguage;
  visibleMenu: ReturnType<typeof visibleMenuElements>;
}

export class CategoriesComponent extends PureComponent<ICategoriesProps> {
  render() {
    const { lang, visibleMenu } = this.props;
    const isVisible = _.includes(visibleMenu, true);
    return (
      isVisible && (
        <div className={b()}>
          {_.map(MenuCategories, (category, key) => (
            <CategoryItem
              category={category}
              key={key}
              lang={lang}
              visibleMenu={visibleMenu}
            />
          ))}
        </div>
      )
    );
  }
}
